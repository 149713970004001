<template>
  <nav>
    <router-link to="/">Home</router-link>
    <router-link to="/events">Events</router-link>
    <router-link to="/photographer-register">Join Photoppz</router-link>
    <router-link to="/events" class="call-to-action">Book a Session</router-link>
  </nav>
</template>

<script>
export default {
  name: 'NavMenu',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
