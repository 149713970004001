import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vuex from 'vuex'

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'


import '@/assets/styles/main.scss';

createApp(App).use(store).use(Vuex).use(router).use(PerfectScrollbar, {
    // watchOptions: true,
    options: {
        suppressScrollX: true
    }
}).mount('#app')
