<template>
    <div class="location-selection-popup">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container row">
                    <div class="left-col col-md-6">
                        <h1 class="heading-1">Photoppz Locations</h1>
                    </div>
                    <div class="right-col col-md-6">
                        <div class="row">
                            <div v-for="territory in territories" class="col-md-6" :key="territory.id">
                                <h2 class="sub-heading green-text">{{ territory.attributes.name }}</h2>
                                <ul>
                                    <li v-for="city in territory.attributes.cities.data" :key="city.id">
                                        <a @click="selectCity(city)" href="#" class="body-text">
                                            {{ city.attributes.name }}
                                        </a>
                                        <span v-if="city.attributes.commingSoonEnabled" class="tag red-text"> coming soon</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <p class="body-text red-text">
                            Click on a "coming soon" location to get notified when we are up and running there.
                        </p>
                        <button class="modal-default-button" @click="$emit('close')">
                            x
                        </button>
                    </div>

<!--                    <div class="modal-header">-->
<!--                        <slot name="header">-->
<!--                            default header-->
<!--                        </slot>-->
<!--                    </div>-->

                </div>
            </div>
        </div>
    </div>

</template>

<script>
// import NavMenu from '@/components/NavMenu'

import axios from "axios";

export default {
    name: 'LocationsPopup',
    data: () => ({
        // showModal: false,
        api_url: process.env.VUE_APP_STRAPI_URL,
        api_auth_key: process.env.VUE_APP_API_AUTH_KEY,
        territories: null,
    }),
    props: {
        // customClass: String
    },
    components: {
        // NavMenu
    },
    methods: {
      getTerritoriesList(){
          axios.get( this.api_url +'/api/territories?populate=*', {
              headers: {
                  Authorization:
                      'Bearer '+this.api_auth_key,
              },
          }).then((response) => {
              // const update_events = response;
              this.territories = response.data.data;
              console.log("DEBUG:");
              console.log(response.data.data);
          });
      },
      selectCity(city){
          this.$store.state.selectedCity = city;
          this.$emit('close');
      }
    },
    mounted() {
      this.getTerritoriesList();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}

.modal-header h3 {
    margin-top: 0;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from, .modal-leave-to {
    opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
