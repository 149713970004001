<template>
  <Carousel id="gallery" :items-to-show="1" :wrap-around="false" v-model="currentSlide">

    <Slide v-for="(image,index) in images" :key="index">
      <div class="carousel__item">
        <img style="width: 100%" v-bind:src="api_url+image.attributes.url" alt="">
      </div>
    </Slide>

    <template #addons>
      <navigation />
    </template>

  </Carousel>

  <Carousel
      id="thumbnails"
      :items-to-show="4"
      :wrap-around="true"
      v-model="currentSlide"
      ref="carousel"
  >
    <Slide v-for="(image, index) in images" :key="index">
      <div class="carousel__item" @click="slideTo(index)">
        <img style="max-width: 100px" v-bind:src="api_url+image.attributes.url" alt="">
      </div>
    </Slide>
  </Carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default defineComponent({
  name: 'SessionGalleryOne',
  props: {
    images: Array
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    api_url: process.env.VUE_APP_STRAPI_URL,
    currentSlide: 0,
  }),
  methods: {
    slideTo(val) {
      this.currentSlide = val
    },
  }
})
</script>