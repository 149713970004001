<template>

    <img @click="toggleMenu" class="mobile-menu-button icon" :class="$route.name" src="../assets/icons/menu.svg">

  <nav v-if="menuOpen" class="mobile-menu-view">

      <router-link class="logo-link" @click="toggleMenu" to="/">
          <img class="logo" alt="logo" src="../assets/Photoppz-logo-full-white.svg">
      </router-link>

      <img @click="toggleMenu" class="mobile-menu-button close icon" src="../assets/icons/close-icon.svg">

        <span @click="showModal = true" class="mobile-location-selection hidden-print">
          <img class="icon" src="../assets/icons/location-icon-green.svg">
          <span v-if="$store.state.selectedCity" class="label body-text">{{ $store.state.selectedCity.attributes.name }}</span>
          <span class="label body-text" v-else>Select location</span>
          <img class="icon" src="../assets/icons/down-arrow-icon-green.svg">
        </span>

        <transition name="modal">
          <locations-popup v-if="showModal" @close="showModal = false"></locations-popup>
        </transition>

      <router-link active-class="active" class="menu-link-item" @click="toggleMenu" to="/">Home</router-link>
      <router-link active-class="active" class="menu-link-item" @click="toggleMenu" to="/events">Events</router-link>
      <router-link active-class="active" class="menu-link-item" @click="toggleMenu" to="/photographer-register">Join Photoppz</router-link>
      <router-link active-class="active" class="menu-link-item call-to-action" @click="toggleMenu" to="/events">Book a Session</router-link>
  </nav>
</template>

<script>
import LocationsPopup from "@/components/LocationsPopup.vue";

export default {
  name: 'MobileMenu',
    components: {LocationsPopup},
    data: () => ({
        menuOpen: false,
        showModal: false,
    }),
  props: {
    msg: String
  },
    methods: {
      toggleMenu(){
        this.menuOpen = !this.menuOpen;
      },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
