<template>
  <section class="upcoming-sessions">
      <div class="row">
          <div class="col-lg-4 col-md-12">
              <ul class="events-list">
                  <li v-for="event in events" :key="event.id" @click="getEventDetails(event)" :class="{ active:event.isActive }">
                      <h3 v-if="event.attributes.eventTitle.length < 27" class="body-text-large-bold">{{ event.attributes.eventTitle }}</h3>
                      <h3 v-if="event.attributes.eventTitle.length >= 27" class="body-text-large-bold" :title="event.attributes.eventTitle">{{  limitedText(event.attributes.eventTitle, 25) }}...</h3>
                      <p class="date-time body-text">
                          <span class="date semi-bold">{{ dateFormatUpdate(event.attributes.startDateTime) }}</span> -
                          <span class="time">{{ dateFormatUpdate2(event.attributes.startDateTime) }} to <span v-if="dateFormatUpdate(event.attributes.startDateTime) != dateFormatUpdate(event.attributes.endDateTime)">{{ dateFormatGetDay(event.attributes.endDateTime) }}&nbsp;</span>{{ dateFormatUpdate2(event.attributes.endDateTime) }}</span>
                      </p>
                  </li>

                  <!--<li class="special">-->
                  <!--  <label>Christmas Season Event</label>-->
                  <!--  <h3 class="body-text-large-bold">Christmas Seasonal Shoot</h3>-->
                  <!--  <p class="date-time body-text">-->
                  <!--    <span class="date semi-bold">November 18th</span> - -->
                  <!--    <span class="time">09:00AM to 5:00PM</span>-->
                  <!--  </p>-->
                  <!--</li>-->

              </ul>
          </div>
          <div class="col-lg-8 col-md-12">
              <div class="event-detail-view" v-if="selectedEvent!== null">
                  <div class="top">
                      <div class="row">
                          <div class="col-md-8">
                              <h3 class="sub-heading">{{ selectedEvent.attributes.eventTitle }}</h3>
                              <p class="date-time body-text">
                                  <!-- November 18th - -->
                                  <span class="date semi-bold">{{ dateFormatUpdate4(selectedEvent.attributes.startDateTime) }}</span> -
                                  <span class="time">{{ dateFormatUpdate2(selectedEvent.attributes.startDateTime) }} to <span v-if="dateFormatUpdate(selectedEvent.attributes.startDateTime) != dateFormatUpdate(selectedEvent.attributes.endDateTime)">{{ dateFormatGetDay(selectedEvent.attributes.endDateTime) }}&nbsp;</span>{{ dateFormatUpdate2(selectedEvent.attributes.endDateTime) }}</span>
                                  <span v-if="eventTimeslots.length!==0" class="label green">Only {{ eventTimeslots.length }} slots left</span>
                              </p>
                          </div>
                          <div class="col-md-4">
                              <button v-on:click="continueBooking()" class="btn-red-filled">
                                  Book your session
                              </button>
                          </div>
                      </div>

                  </div>
                  <div class="bottom">
                      <div class="row">
                          <div class="col-md-8">

                              <session-gallery-one v-if="selectedEvent!== null" :images="selectedEvent.attributes.location.data.attributes.imageGallery.data" />

                          </div>
                          <div class="col-md-4">

                              <perfect-scrollbar class="session-selection">
                                  <h3 class="body-text-large-bold">Select session</h3>
                                  <p class="body-text-smaller" v-if="!timeslotValidationStatus">Please select an event session to continue.</p>
                                  <ul>
                                      <li v-if="eventTimeslots.length==0">Sorry, No available sessions</li>
                                      <li v-for="slot in eventTimeslots" :key="slot.id"><button v-if="slot.attributes.timeslot_status.data.id===1" @click.prevent="toggleStatus(slot)" :class="{ active:slot.isActive }">{{  slot.attributes.label }}</button></li>
                                  </ul>
                              </perfect-scrollbar>

                              <button v-on:click="continueBooking()" class="mobile-only btn-red-filled">
                                  Book your session
                              </button>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
// import 'vue3-carousel/dist/carousel.css'
// import { Carousel, Slide, Navigation } from 'vue3-carousel'
import SessionGalleryOne from '@/components/SessionGalleryOne'
import axios from "axios";
// import { TestimonialGalleryOne } from '@/components/TestimonialGalleryOne'

// carousel ref: https://ismail9k.github.io/vue3-carousel/configs.html

export default {
  name: 'UpcomingSessions',
  components: {
    SessionGalleryOne,
  },
  data: () => ({
    // Data
    api_url: process.env.VUE_APP_STRAPI_URL,
    api_auth_key: process.env.VUE_APP_API_AUTH_KEY,
    sampleData: 0,
    events: [],
    eventTimeslots: null,
    selectedEvent: null,
    timeslotValidationStatus: true,
  }),
  methods: {
    toggleStatus(slot) {
        slot.isActive = !slot.isActive;
        if (slot.isActive) {
            console.log("DEBUG: ");
            console.log(slot);
            // this.selectedTimeslots.push(slot);
            this.$store.state.selectedTimeslots.push(slot);
            // this.$store.state.selectedTimeslots = slot;
            // console.log("active: " + typeof this.selectedTimeslots);
        } else {
            // this.$store.state.selectedTimeslots.push(slot);
            this.$store.state.selectedTimeslots.splice(this.$store.state.selectedTimeslots.indexOf(slot), 1);
            // console.log("slot:")
            // console.log(slot)
        }

    },
    limitedText(data,num){
      const reqdString =
          data.split("").slice(0, num).join("");
      return reqdString;
    },
    getEventsList(){
      axios.get( this.api_url +'/api/events?populate=*&populate=location.imageGallery&populate=event_categories', {
        headers: {
          Authorization:
              'Bearer '+this.api_auth_key,
        },
      }).then((response) => {
        this.events = response.data.data.slice(0,4);

        // Get event time slots
        if (this.events[0]) {
          this.events[0].isActive = true;
          this.selectedEvent = this.events[0];
          this.getEventDetails(this.selectedEvent);
          // this.getEventTimeslots(this.events[0]);
        }
      });
    },
    getEventDetails(selectedEvent) {
      this.selectedEvent = selectedEvent;
      this.events.map((event) => event.isActive=false);
      selectedEvent.isActive = true;

      // Get event timeslots
      this.getEventTimeslots(selectedEvent);

      // Save selected event
        this.$store.state.eventID = this.selectedEvent.id;
        // this.$store.state.eventID = this.selectedEvent.attributes.eventID;
        this.$store.state.location = this.selectedEvent.attributes.eventTitle;

    },
    getEventTimeslots(selectedEvent){

      // Switch status display
      // this.events.map((item) => item.isActive=false);
      // selectedDate.isActive = true;

      console.log("selectedDate =");
      console.log(selectedEvent);
      console.log(selectedEvent.attributes.startDateTime);
      console.log(this.dateFormatUpdate3(selectedEvent.attributes.startDateTime));
      // let selectedDateString = this.dateFormatUpdate3(selectedEvent.attributes.startDateTime);
        axios.get(this.api_url + '/api/timeslots?filters[event][id][$eq]=' + selectedEvent.id + '&populate=*&filters[timeslot_status][id][$eq]=1', {
                // axios.get('http://localhost:1337/api/timeslots?filters[event][id][$eq]=' + selectedEvent.attributes.eventID + '&populate=*', {
            // axios.get('http://localhost:1337/api/timeslots?filters[event][id][$eq]=' + selectedEvent.attributes.eventID + '&filters[date][$eq]=' + selectedDateString + '&populate=*', {
        headers: {
          Authorization:
              'Bearer '+this.api_auth_key,
        },
      }).then((response) => {
            this.eventTimeslots = response.data.data;

          // Get event time slots
            //   if (this.eventTimeslots[0]) {
            //       this.eventTimeslots[0].isActive = true;
            //       // this.selectedEvent = this.eventTimeslots[0];
            //       // this.getEventDetails(this.selectedEvent);
            //       // this.getEventTimeslots(this.events[0]);
            //       // this.$store.state.selectedTimeslots.push(this.eventTimeslots[0]);
            //       console.log("test val:")
            //       console.log(this.eventTimeslots[0])
            //   }
      });
    },
    dateFormatUpdate(date){
      const custom_date = new Date(date);
      // DATE FORMAT : Tuesday, March 28

      // Get day
      let formatDay = { day: 'numeric' };
      let getDay = custom_date.toLocaleString("en-US", formatDay);

      // Get weekday
      // let formatWeekday = { weekday: 'long' };
      // let getWeekday = custom_date.toLocaleString("en-US", formatWeekday);

      // Get month
      let formatMonth = { month: 'long' };
      let getMonth = custom_date.toLocaleString("en-US", formatMonth);

      // return getWeekday + ', ' + getMonth + ' ' + this.dateOrdinal(getDay);
      return getMonth + ' ' + this.dateOrdinal(getDay);
    },
    dateFormatUpdate2(date){
      const custom_date = new Date(date);
      // DATE FORMAT : Tuesday, March 28
      let options = { hour:'numeric', minute:'numeric' };
      return custom_date.toLocaleString("en-US", options);
    },
    dateFormatUpdate3(date){
      const custom_date = new Date(date);
      // DATE FORMAT : Tuesday, March 28
      // let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      // return custom_date.toLocaleDateString("en-US", options);
      // let getMonthString = custom_date.getMonth();
      return custom_date.getFullYear() + '-' + String(custom_date.getMonth() + 1).padStart(2, '0') + '-' + String(custom_date.getDate()).padStart(2, '0');
      // REF: https://www.tabnine.com/code/javascript/query/%22new%22%2B%22Date%22
    },
    dateFormatUpdate4(date){
      const custom_date = new Date(date);
      // DATE FORMAT : Tuesday, March 28
      let dayFormat = { day: 'numeric' };
      let getDate = custom_date.toLocaleString("en-US", dayFormat);

      let options = { month: 'long' };
      return custom_date.toLocaleDateString("en-US", options) + ' ' + this.dateOrdinal(getDate);
    },
    dateFormatGetDay(date){
      const custom_date = new Date(date);
      // Get day
      let formatDay = { day: 'numeric' };
      let getDay = custom_date.toLocaleString("en-US", formatDay);
      return this.dateOrdinal(getDay)
    },
    dateOrdinal(day){
      const number = Number(day)
      if(!number || (Math.round(number) !== number)) {
        return number
      }
      let signal = (number < 20) ? number : Number(('' + number).slice(-1))
      switch(signal) {
        case 1:
          return number + 'st'
        case 2:
          return number + 'nd'
        case 3:
          return number + 'rd'
        default:
          return number + 'th'
      }
    },
    continueBooking(){
        if (this.$store.state.selectedTimeslots.length!==0) {
            this.$router.push('/checkout');
        } else {
            // alert("Please select");
            this.timeslotValidationStatus = false;
        }
    },
  },
  created() {
    this.getEventsList();
  }
}
</script>
