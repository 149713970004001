<template>
  <header v-bind:class="customClass">
    <section class="container">

        <div class="row">
            <div class="col-md-6 col-6">

                <div class="row">
                    <div class="col-6">
                        <router-link to="/">
                            <img class="logo" alt="logo" src="../assets/Photoppz-logo.svg">
                        </router-link>
                    </div>
                    <div class="col-6">
                        <span @click="showModal = true" class="location-selection hidden-print">
                          <img class="icon" src="../assets/icons/location-icon-green.svg">
                          <span v-if="$store.state.selectedCity" class="label body-text">{{ $store.state.selectedCity.attributes.name }}</span>
                          <span class="label body-text" v-else>Select location</span>
                          <img class="icon" src="../assets/icons/down-arrow-icon-green.svg">
                        </span>

                        <transition name="modal">
                            <locations-popup v-if="showModal" @close="showModal = false"></locations-popup>
                        </transition>
                    </div>
                </div>

            </div>

            <div class="col-md-6 col-6">
                <!-- <img class="mobile-menu-button icon" src="../assets/icons/menu.svg">-->
                <mobile-menu></mobile-menu>
                <nav-menu class="nav-menu hidden-print"></nav-menu>
            </div>
        </div>


    </section>
  </header>

</template>

<script>
import NavMenu from '@/components/NavMenu'
import LocationsPopup from '@/components/LocationsPopup'
import MobileMenu from "@/components/MobileMenu.vue";

export default {
  name: 'SiteHeader',
  data: () => ({
    showModal: false,
  }),
  props: {
    customClass: String
  },
  components: {
      MobileMenu,
    NavMenu,
    LocationsPopup
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
