<template>

  <vueper-slides
      :dragging-distance="70"
      :bullets="false"
      prevent-y-scroll>
    <vueper-slide
        v-for="(testimonial, i) in testimonials"
        :key="i"
        :title="testimonial.attributes.title"
        :content="testimonial.attributes.content">
      <template #content>
        <div class="slide-block row">
          <div class="col-md-5">
            <SessionGalleryOne :images="testimonial.attributes.imageGallery.data"/>
          </div>
          <div class="col-md-6 right">
            <!--{{ slide.content }}-->
            <h3>“{{ testimonial.attributes.title }}”</h3>
            <p>{{ testimonial.attributes.content }}</p>
            <div class="bottom-content">
              <span>- {{ testimonial.attributes.customer }}</span>
<!--              <router-link class="link" to="/">Read full review</router-link>-->
            </div>
          </div>
        </div>

      </template>


      <!--<template #content>-->
      <!--  <div class="slide-block row">-->
      <!--    <div class="col-md-5">-->
      <!--      <SessionGalleryOne/>-->
      <!--    </div>-->
      <!--    <div class="col-md-6 right">-->
      <!--      &lt;!&ndash;{{ slide.content }}&ndash;&gt;-->
      <!--      <h3>“Really pleased with the entire service!”</h3>-->
      <!--      <p>Really pleased with the entire service! The booking process was simple and easy. Any queries were answered quickly. Our photographer communicated with us prior to the day and understood exactly what we were looking for. The quality of the photos were excellent we’ve had so many compliments on how beatiful they ...</p>-->
      <!--      <div class="bottom-content">-->
      <!--        <span>- Melissa Fernandez</span>-->
      <!--        <router-link class="link" to="/">Read full review</router-link>-->
      <!--      </div>-->
      <!--    </div>-->
      <!--  </div>-->

      <!--</template>-->


    </vueper-slide>
  </vueper-slides>

</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
// REF: https://antoniandre.github.io/vueper-slides/?ref=madewithvuejs.com

import SessionGalleryOne from '@/components/SessionGalleryOne'
import axios from "axios";

export default {
  name: 'TestimonialsCarousel',
  components: {
    VueperSlides,
    VueperSlide,
    SessionGalleryOne
  },
  data: () => ({
    debug: null,
    api_url: process.env.VUE_APP_STRAPI_URL,
    api_auth_key: process.env.VUE_APP_API_AUTH_KEY,
    slides: [
      {
        title: 'Slide #1',
        content: 'Slide 1 content.'
      },
      {
        title: 'Slide #2',
        content: 'Slide 2 content.'
      }
    ],
    testimonials: [
      // {
      //   "id": 1,
      //   "attributes": {
      //     "testimonialID": "1",
      //     "title": "\"Placerat in egestas erat imperdiet sed euismod nisi porta\"",
      //     "customer": "Volutpat maecenas",
      //     "status": true,
      //     "createdAt": "2023-03-24T06:19:26.839Z",
      //     "updatedAt": "2023-03-24T06:19:27.781Z",
      //     "content": "Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Placerat in egestas erat imperdiet sed euismod nisi porta. Egestas congue quisque egestas diam in arcu cursus euismod. Ut enim blandit volutpat maecenas volutpat blandit. Id neque aliquam vestibulum morbi. Morbi leo urna molestie at elementum eu facilisis sed. "
      //   }
      // },
    ],
  }),
  methods: {
    getTestimonialsList(){
      axios.get( this.api_url +'/api/client-testimonials?populate=*&populate=imageGallery', {
        headers: {
          Authorization:
              'Bearer '+this.api_auth_key,
        },
      }).then(response =>
              // const update_events = response;
              this.testimonials = response.data.data
          // console.log(response.data);
      );


    },
  },
  created() {
    this.getTestimonialsList();
  }
}
</script>