<template>
  <ul>
    <li @click="currentSlide=0" v-bind:class="[currentSlide==0 ? 'active' : '']">Top Photographers</li>
    <li @click="currentSlide=1" v-bind:class="[currentSlide==1 ? 'active' : '']">Vetted locations</li>
    <li @click="currentSlide=2" v-bind:class="[currentSlide==2 ? 'active' : '']">Affordable</li>
  </ul>

  <carousel ref="carousel" v-model="currentSlide" :items-to-show="1" :breakpoints="breakpoints" :wrap-around="true" :pause-autoplay-on-hover="true">
    <!--        <slide v-for="slide in 10" :key="slide">-->
    <!--            {{ slide }}-->
    <!--        </slide>-->
    <!--:autoplay="3000"-->

    <slide :key="1">
      <div class="slide-block row">
        <div class="col-md-5">
          <img class="thumbnail" src="../assets/images/home-values/top-photography-talents.png">
        </div>
        <div class="col-md-7 right">
          <h3>Top photography talent</h3>
          <p>We have a rigorous screening process to ensure we have the best of the best photographers that can capture memorable moments.</p>
        </div>
      </div>
    </slide>

    <slide :key="2">
      <div class="slide-block row">
        <div class="col-md-5">
          <img class="thumbnail" src="../assets/images/home-values/vetted-locations.png">
        </div>
        <div class="col-md-7 right">
          <h3>Vetted Locations</h3>
          <p>We have carefully selected the best locations for each type of shoot to make sure it adds abit of a magical touch to your photoshoot.</p>
        </div>
      </div>
    </slide>

    <slide :key="3">
      <div class="slide-block row">
        <div class="col-md-5">
          <img class="thumbnail" src="../assets/images/home-values/affordable-and-accessible.png">
        </div>
        <div class="col-md-7 right">
          <h3>Affordable and accessible</h3>
          <p>You can get professional looking photos done for an affordable price. Additionally, only pay for the photos that you select. Professional photoshoots have never been more accessible.</p>
        </div>
      </div>
    </slide>

    <template #addons>
      <navigation />
    </template>

  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
// carousel ref: https://ismail9k.github.io/vue3-carousel/configs.html

export default {
  name: 'ValuesCarousel',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    // carousel settings
    currentSlide: 2,
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      300: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
    },
  }),
}
</script>