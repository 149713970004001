import { createStore } from "vuex";
import createPersistedState from "vuex-plugin-persistedstate";

const store = createStore({
  state: {
    location: 'Wing Haven Gardens Test',
    orderNumber: '#000',
    eventID: null,
    selectedTimeslots: [],
    selectedCity: null,
    selectedMapEvent: null, // TODO: RM
    customer: {
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      postcode: null,
      address: null,
      promoContentConsent: null,
    },
  },
  mutations: {
    increment (state) {
      state.age++
    }
  },
  plugins: [createPersistedState()],
});

export default store;

// const vuexPersist = new VuexPersist({
//   key: 'user', // unique key for saving state properties in the browser storage
//   storage: window.localStorage, // select the type of storage used (eg - localStorage, sessionStorage, etc)
//   reducer: (state) => ({ name: state.name, age: state.age }) // select the state properties that you want persisted
// })
//
// export default new Vuex.Store({
//   state: {
//     name: "Alex",
//     age: "23"
//   },
//   plugins: [vuexPersist.plugin] // adding vuex-persist as a store plugin
// })



// export default createStore({
//   state() {
//     return {
//       count: 90
//     }
//   },
//   getters: {
//   },
//   mutations: {
//     increment (state) {
//       state.count++
//     }
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
